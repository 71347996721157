import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import css from './ListingPage.module.css';
const primiumSubscriptionID = process.env.REACT_APP_PREMIUM_SUBSCRIPTION_ID;
const basicSubscriptionID = process.env.REACT_APP_BASIC_SUBSCRIPTION_ID;

const SectionVideoMaybe = props => {
  const { options, publicData, author } = props;

  const stringToHTML = function (str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  };


  const userProfile = author ? author.attributes.profile : false;
  const subscription = userProfile ? userProfile.publicData ? (userProfile.publicData.subscriptions ? 
                                        (userProfile.publicData.subscriptions.includes(primiumSubscriptionID) ? 'premium' :
                                        (userProfile.publicData.subscriptions.includes(basicSubscriptionID) ? 'standard' : false)
                                        ) : false) : false : false;

  const isPremium = subscription === 'premium';
  const videoLink = publicData.video ? publicData.video : "";

  if (!isPremium || videoLink === "") {
    return null;
  }


  return (
    <div className={css.sectionVideo}>
      
      {/* <Iframe url={videoLink}
        width="100%"
        height="450px"
        id="myId"
        className="promoVideo"
        display="initial"
        position="relative"/> */}
        <div className={css.promoVideoWrapper} dangerouslySetInnerHTML={{__html: videoLink}}></div>
  
    </div>
  );
};

export default SectionVideoMaybe;
